/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }){
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/room-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(()=>{
    actions.roll.play()
    actions.gotem.play()
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Circle003" position={[1.28, 1.97, 1.14]} rotation={[0, 0, -Math.PI / 2]} />
        <group name="text_aboutme" position={[-2.94, 3.44, -2.29]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh name="Text005" geometry={nodes.Text005.geometry} material={materials.controller_white} />
          <mesh name="Text005_1" geometry={nodes.Text005_1.geometry} material={materials['Material.003']} />
          <mesh name="Text005_2" geometry={nodes.Text005_2.geometry} material={materials.floor} />
          <mesh name="Text005_3" geometry={nodes.Text005_3.geometry} material={materials.wall_landing} />
          <mesh name="Text005_4" geometry={nodes.Text005_4.geometry} material={materials.BLACK} />
          <mesh name="Text005_5" geometry={nodes.Text005_5.geometry} material={materials.controller_yellow} />
          <mesh name="Text005_6" geometry={nodes.Text005_6.geometry} material={materials['Material.004']} />
          <mesh name="Text005_7" geometry={nodes.Text005_7.geometry} material={materials.controllers} />
          <mesh name="Text005_8" geometry={nodes.Text005_8.geometry} material={materials.toybag} />
          <mesh name="Text005_9" geometry={nodes.Text005_9.geometry} material={materials['Material.002']} />
          <mesh name="Text005_10" geometry={nodes.Text005_10.geometry} material={materials.controller_blue} />
          <mesh name="Text005_11" geometry={nodes.Text005_11.geometry} material={materials.Material} />
          <mesh name="Text005_12" geometry={nodes.Text005_12.geometry} material={materials['Material.001']} />
          <mesh name="Text005_13" geometry={nodes.Text005_13.geometry} material={materials.controllers_red} />
          <mesh name="Text005_14" geometry={nodes.Text005_14.geometry} material={materials.controller_grey} />
          <mesh name="Text005_15" geometry={nodes.Text005_15.geometry} material={materials.orange} />
          <mesh name="Text005_16" geometry={nodes.Text005_16.geometry} material={materials['Material.007']} />
          <mesh name="Text005_17" geometry={nodes.Text005_17.geometry} material={materials.tennis} />
          <mesh name="Text005_18" geometry={nodes.Text005_18.geometry} material={materials['Material.010']} />
          <mesh name="Text005_19" geometry={nodes.Text005_19.geometry} material={materials.AIRPLANE_YELLOW} />
          <mesh name="Text005_20" geometry={nodes.Text005_20.geometry} material={materials['Material.009']} />
          <mesh name="Text005_21" geometry={nodes.Text005_21.geometry} material={materials['Material.011']} />
          <mesh name="Text005_22" geometry={nodes.Text005_22.geometry} material={materials['Material.005']} />
        </group>
        <mesh name="text_aboutme001" geometry={nodes.text_aboutme001.geometry} material={materials.BLACK} position={[-2.94, 3.44, -2.29]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh name="text_aboutme002" geometry={nodes.text_aboutme002.geometry} material={materials['Material.013']} position={[-2.94, 3.44, -2.29]} rotation={[Math.PI / 2, 0, 0]} />
        <group name="hULK" position={[-0.86, 1.41, 0.65]}>
          <mesh name="Walls002" geometry={nodes.Walls002.geometry} material={materials.BLACK} />
          <mesh name="Walls002_1" geometry={nodes.Walls002_1.geometry} material={materials.hulk_material} />
        </group>
        <group name="Plane003" position={[2.27, 4.64, 1.21]} rotation={[0.1, -0.39, -0.4]}>
          <mesh name="Walls004" geometry={nodes.Walls004.geometry} material={materials.BLACK} />
          <mesh name="Walls004_1" geometry={nodes.Walls004_1.geometry} material={materials.controller_yellow} />
          <mesh name="Walls004_2" geometry={nodes.Walls004_2.geometry} material={materials.controller_blue} />
          <mesh name="Walls004_3" geometry={nodes.Walls004_3.geometry} material={materials.controllers_red} />
          <mesh name="Walls004_4" geometry={nodes.Walls004_4.geometry} material={materials.controller_grey} />
          <mesh name="Walls004_5" geometry={nodes.Walls004_5.geometry} material={materials.controller_white} />
          <mesh name="Walls004_6" geometry={nodes.Walls004_6.geometry} material={materials.AIRPLANE_YELLOW} />
        </group>
        <group name="pig" position={[-0.72, 1.19, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials['Material.014']} />
          <mesh name="Sphere_1" geometry={nodes.Sphere_1.geometry} material={materials.BLACK} />
          <mesh name="Sphere_2" geometry={nodes.Sphere_2.geometry} material={materials['Material.015']} />
        </group>
        <mesh name="Circle" geometry={nodes.Circle.geometry} material={materials.BLACK} position={[1.21, 1.83, 1.37]} rotation={[1.08, 0.36, 1.54]} />
        <mesh name="Circle001" geometry={nodes.Circle001.geometry} material={materials.BLACK} position={[1.21, 1.83, 1.21]} rotation={[1.83, 0.29, 1.74]} />
        <mesh name="Circle002" geometry={nodes.Circle002.geometry} material={materials.BLACK} position={[1.25, 1.75, 1.29]} rotation={[1.62, 0.93, 1.55]} />
        <mesh name="hULK002" geometry={nodes.hULK002.geometry} material={materials['Material.006']} position={[-0.86, 1.4, 0.65]} />
        <mesh name="cOUCH002" geometry={nodes.cOUCH002.geometry} material={materials.controller_blue} position={[-0.86, 1.43, 0.65]} />
        <mesh name="tvPanel" geometry={nodes.tvPanel.geometry} material={nodes.tvPanel.material} position={[-0.86, 1.4, 0.65]} />
        <group name="pokeball_white003" position={[-0.86, 1.4, 0.65]}>
          <mesh name="Walls082" geometry={nodes.Walls082.geometry} material={materials.BLACK} />
          <mesh name="Walls082_1" geometry={nodes.Walls082_1.geometry} material={materials.controllers_red} />
          <mesh name="Walls082_2" geometry={nodes.Walls082_2.geometry} material={materials.controller_white} />
        </group>
        <mesh name="stars" geometry={nodes.stars.geometry} material={materials['Material.008']} position={[1.51, 1.07, 0.81]} scale={0.19} />
        <mesh name="google" geometry={nodes.google.geometry} material={materials.google} position={[1.83, 2.03, 2.25]} rotation={[1.58, 0.07, 1.44]} />
        <mesh name="codingImage" geometry={nodes.codingImage.geometry} material={materials.codingImage} position={[1.84, 2.08, 2.76]} rotation={[1.57, 0, -1.57]} />
      </group>
    </group>
  )
}

useGLTF.preload('/Room-transformed.glb')
